import domReady from '@roots/sage/client/dom-ready';
import Swiper, { Autoplay, Navigation, Pagination, Controller } from "swiper";

/**
 * Application entrypoint
 */
domReady(async () => {

  const testimonialSlider = document.querySelector(".testimonial-slider");
    if (testimonialSlider) {
      let swiper = new Swiper(".testimonial-slider", {
      modules: [Autoplay, Navigation, Pagination, Controller],
      loop: true,
      autoplay: false,
      slidesPerView: 1,
      pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
      },
    });
  }



});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
